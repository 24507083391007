var width = $('.root_container').width() / 2;

var height = $(window).height() ;

$("#sidebar").attr("style", $("#sidebar").attr("style") + ";width:" + width+"px;height:" + height + "px"); 

$(document).on("click",".navbar-toggler", function(){
  var width = $('.root_container').width() / 2;

  var height = $(window).height();

  $("#sidebar").attr("style", $("#sidebar").attr("style") + ";width:" + width + "px;height:" + height + "px");

  if ($('#sidebar').is(":visible")){

    $('#sidebar').animate({width:'toggle'});

  }else{
    $('#sidebar').animate({width:'toggle'},350);
  }

});
